<template>
  <div class='system-configs-table'>
    <a-table :columns='columns' :data-source='data' row-key='id' :loading='loading' :scroll='{ x: 1600}'
             :pagination="metadata" @change="handleTableChange">
      <template slot='stt' slot-scope='item, data, index'> {{ (metadata.current - 1) * metadata.pageSize + index + 1 }}</template>
      <template slot='email' slot-scope='item'><b>{{item.email}}</b></template>
      <template slot='walletAddress' slot-scope='item'>
        <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.walletAddress)' />
        <span>{{item.walletAddress}}</span>
      </template>
      <template slot='status' slot-scope='item'>
        <a-tag color='green' v-if='item.status === 1'>Active</a-tag>
        <a-tag color='red' v-if='item.status === 0'>Inactive</a-tag>
      </template>
      <template slot='actions' slot-scope='item'>
        <a-tooltip v-if='item.status === 1'>
          <template slot="title">
            Inactive user
          </template>
          <a-button type='link' size='small' @click='onChangeStatus(item)'>
            <a-icon type="close-circle" theme='twoTone' two-tone-color="#ff5500" />
          </a-button>
        </a-tooltip>
        <a-tooltip v-if='item.status === 0'>
          <template slot="title">
            Active user
          </template>
          <a-button type='link' size='small' @click='onChangeStatus(item)'>
            <a-icon type="check-circle" theme='twoTone' two-tone-color="#52c41a" />
          </a-button>
        </a-tooltip>
      </template>
    </a-table>

    <users-status ref='usersStatus' @fetch='fetchData' />
  </div>
</template>

<script>
import UsersService from '@/services/app/users.service'
import { copyURL} from '@/helpers/common'

export default {
  name: 'UsersTable',
  components: {
    UsersStatus: () => import('@/components/Pages/Users/UsersStatus')
  },
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: 'Order',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'stt' }
        },
        {
          dataIndex: 'id',
          title: 'ID',
          width: 120,
          align: 'center',
        },
        {
          title: 'Email',
          scopedSlots: { customRender: 'email' },
          ellipsis: true
        },
        {
          title: 'Status',
          scopedSlots: { customRender: 'status' },
          width: 160,
          align: 'center'
        },
        {
          title: 'Wallet address',
          scopedSlots: { customRender: 'walletAddress' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Created at',
          width: 200,
          align: 'center',
          dataIndex: 'createdAt'
        },
        {
          title: 'Updated at',
          width: 200,
          align: 'center',
          dataIndex: 'updatedAt'
        },
        {
          title: '#',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right',
          width: 140,
          align: 'center'
        }
      ],
      metadata: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  methods: {
    fetchData(query) {
      this.metadata = {
        current: query.page || 1,
        pageSize: query.pageSize || 10,
        total: query.total || 0
      }
      const params = query ? {...query} : {}
      this.loading = true
      UsersService.getList(params).then(res => {
        if (res.code === 1) {
          this.data = res.data ? [...res.data] : []
          this.metadata.total = res.totalElement || 0;
          this.metadata = {...this.metadata};
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    copyURL,
    onChangeStatus(item) {
      this.$refs.usersStatus.showModal(item)
    },
    handleTableChange(pagination) {
      this.$emit('change', pagination)
    },
  }
}
</script>

<style scoped lang='scss'>
.system-configs-table {
  .ic-copy {
    color: #52c41a;
    cursor: pointer;
  }
}
</style>
